<template>
	<div class="news">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isAccessModalOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image grey"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="NewsIcon"
				title="News"
				colour="grey"
			/>
			<div class="section-gap section-news">
				<div class="section-title-wrap">
					<h2 class="section-title red">Featured News</h2>
				</div>
				<div class="news-card-wrap" v-dragscroll>
					<NewsCard
                        v-for="article in featured"
                        :key="article.id"
						:id="article.id"
						:image="article.image_url"
						:title="article.title"
						:date="article.created_at | moment('Do MMMM YYYY')"
					/>
				</div>
				<div class="section-gap">
					<div class="date-news-wrap">
						<span class="date-line"></span>
						<div class="date-wrap" v-dragscroll>
							<a
                                v-for="(articles, date) in all"
                                :key="date"
                                href="#"
                                :class="{ 'active': active === date }"
                                @click.prevent="active = date"
                            >{{ date | moment('MMMM YYYY') }}</a>
						</div><!-- date wrap -->
					</div><!-- date news wrap -->
					<div class="news-card-wrap-alt">
						<NewsCard
                            v-for="article in all[active]"
                            :key="article.id"
							class="news-card-alt"
                            :id="article.id"
							:image="article.image_url"
							:title="article.title"
							:date="article.created_at | moment('Do MMMM YYYY')"
						/>
					</div>
				</div>
				<div class="section-gap section-ports">
					<div class="section-title-wrap">
						<h2 class="section-title">Featured Resources</h2>
	                    <router-link :to="{ name: 'Resources' }" class="page-link page-link-red">View more &raquo;</router-link>
					</div>
					<div class="card-row-wrap-alt" v-dragscroll>
						<CardRow
                            v-for="resource in resources"
                            :key="resource.id"
							eyebrow
							routeName="ResourcesSingle"
                            :id="resource.id"
							class="card-row-block-resources card-row-block-alt"
							:title="resource.title"
							:text="resource.simplified_content"
							:tag="resource.category"
							:type="resource.simplified_type"
						/>
					</div><!-- port card wrap -->
				</div>
			</div>
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import NewsService from '@/services/NewsService';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'News',
		components: {
			Nav,
			NewsCard,
			HeaderNavBar,
			CardRow,
			Menu,
			SectionTitle,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
                featured: [],
                all: [],
                resources: [],
                active: ''
	        };
	    },
	    methods: {
            getNews () {
                return NewsService.getNews().then((response) => {
                    this.featured = response.data.featured;
                    this.all = response.data.all;
                    this.resources = response.data.resources;
                    if (Object.keys(this.all)) {
                        this.active = Object.keys(this.all)[0];
                    }
                });
            },
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
		mounted () {
            this.getNews();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title {
		&.red {
			color: #C83254;
		}
	}
	.date-news-wrap {
		margin-top: 50px;
		margin-bottom: 25px;
		position: relative;
		.date-line {
			position: absolute;
			height: 2px;
			background-color: $light;
			display: flex;
		    overflow-x: auto;
		    min-width: 100vw;
			left: 0;
			bottom: 0;
			padding-right: 56px;
		}
		.date-wrap {
			display: flex;
		    overflow-x: auto;
		    min-width: 100vw;
		    padding-right: 56px;
		    -webkit-overflow-scrolling: touch;
		    &::-webkit-scrollbar {
		        display: none;
		    }
		    a {
		    	flex-shrink: 0;
		    	color: $light;
		    	font-weight: $weight_600;
		    	letter-spacing: -0.43px;
		    	font-size: 17px;
		    	line-height: 20px;
		    	padding-bottom: 9px;
		    	margin-right: 20px;
		    	position: relative;
		    	&:after {
		    		content: '';
		    		height: 3px;
		    		width: 100%;
		    		position: absolute;
		    		bottom: 0;
		    		left: 0;
		    		transition: 0.3s ease;
		    	}
		    	&.active {
		    		color: $blue_base;
		    		&:after {
		    			background: $blue_base;
		    		}
		    	}
		    }
		}
	}
</style>
